import logoThairun from './assets/images/logo.png'
import iconRunX from './assets/images/logo-small.png'
import logoRunX from './assets/images/runx.png'
import logoMenu from './assets/images/white_logo.png'
import logoHeyjom from './assets/images/heyjom-logo.png'
import supportedBanks from '~/data/supportedBanks.json'
import supportedBankMalayList from '~/data/supportedBanksMalay.json'

const platforms = [{
  platform: 'thai',
  webBaseUrl: 'https://photo.thai.run',
  domains: ['admin.photo.thai.run', 'admin.photo.phuket.run', 'pro.photo.thai.run', 'pro.photo.phuket.run', 'admin.photo.thai.local'],
  logo: logoThairun,
  title: 'Photo ThaiRun Admin',
  logo_menu: logoMenu,
  // singlePhotoPrices: [60, 70, 80],
  paymentMethods : ["omise_card", "omise_truemoney", "scb_qr", "linepay_web", "omise_points_citi"],
  singlePhotoPrices: [70, 80, 90, 100],
  singleVideoPrices: [300],
  currency: 'THB',
  minimumTransferRequestAmount: 500,
  transferFee: 30,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  supportedBanks,
  payoutEnabled: true,
  payoutProvider: 'opn',
  lineProtographer: 'แจ้งช่างภาพสมัครใหม่ ยืนยันตัวตนเข้ากลุ่ม LINE PHOTO THAIRUN…',
  email: 'photo@thai.run',
  enableVideoSales: true
}, {
  platform: 'malay',
  webBaseUrl: 'https://photo.harimau.run',
  domains: ['admin.photo.harimau.run', 'staging.admin.photo.harimau.run', 'pro.photo.harimau.run', 'staging.pro.photo.harimau.run', 'admin.photo.malay.local'],
  logo: iconRunX,
  title: 'Photo Platform Harimau.Run',
  // singlePhotoPrices: [15, 20],
  paymentMethods : ["omise_card", "omise_duitnow_obw", "omise_touchngo", "omise_boost"],
  singlePhotoPrices: [15, 16, 17, 18, 19, 20, 25, 30],
  singleVideoPrices: [],
  currency: 'MYR',
  minimumTransferRequestAmount: 100,
  transferFee: 2,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  supportedBanks: supportedBankMalayList,
  payoutEnabled: true,
  payoutProvider: 'manual',
  logo_menu: iconRunX,
  lineProtographer: '',
  email: 'photo@harimau.run',
  enableVideoSales: false
}, {
  platform: 'viet',
  webBaseUrl: 'https://photo.viet.run',
  domains: ['admin.photo.viet.run', 'staging.admin.photo.viet.run', 'pro.photo.viet.run', 'staging.pro.viet.run', 'admin.photo.viet.local'],
  logo: iconRunX,
  title: 'Photo Platform Viet.Run',
  singlePhotoPrices: [],
  paymentMethods : ["omise_card"],
  singleVideoPrices: [],
  currency: 'VND',
  minimumTransferRequestAmount: 500000,
  transferFee: 10000,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  supportedBanks,
  payoutEnabled: false,
  payoutProvider: 'manual',
  logo_menu: iconRunX,
  lineProtographer: '',
  email: '',
  enableVideoSales: false
}, {
  platform: 'indo',
  webBaseUrl: 'https://photo.indo.run',
  domains: ['admin.photo.indo.run', 'staging.admin.photo.indo.run', 'pro.photo.indo.run', 'staging.pro.indo.run', 'admin.photo.indo.local'],
  logo: iconRunX,
  supportedBanks: [],
  payoutEnabled: false,
  paymentMethods : ["midtrans_card_maybank", "midtrans_card", "midtrans_qris"],
  payoutProvider: 'midtrans',
  title: 'Photo Platform Indo.Run',
  singlePhotoPrices: [50000],
  singleVideoPrices: [],
  currency: 'IDR',
  minimumTransferRequestAmount: 300000,
  transferFee: 10000,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  logo_menu: iconRunX,
  lineProtographer: '',
  email: '',
  enableVideoSales: false
}, {
  platform: 'heyjom',
  webBaseUrl: 'https://photo.heyjom.com',
  domains: ['admin.photo.heyjom.com', 'staging.admin.photo.heyjom.com', 'pro.photo.heyjom.com', 'staging.pro.heyjom.com', 'admin.photo.heyjom.local'],
  logo: logoHeyjom,
  title: 'Photo Platform Heyjom',
  // singlePhotoPrices: [15, 19],
  paymentMethods : ["omise_card", "omise_duitnow_obw", "omise_touchngo", "omise_boost"],
  singlePhotoPrices: [11, 15, 16, 17, 18, 19, 20, 25, 30],
  singleVideoPrices: [],
  currency: 'MYR',
  minimumTransferRequestAmount: 4,
  transferFee: 2,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  supportedBanks: supportedBankMalayList,
  payoutEnabled: true,
  payoutProvider: 'manual',
  logo_menu: logoHeyjom,
  lineProtographer: '',
  email: '',
  enableVideoSales: false
}, {
  platform: 'brunei',
  webBaseUrl: 'https://photo.brunei.run',
  domains: ['admin.photo.brunei.run', 'staging.admin.photo.brunei.run', 'pro.photo.brunei.run', 'staging.pro.photo.brunei.run', 'admin.photo.brunei.local'],
  logo: iconRunX,
  title: 'Photo Platform Brunei.Run',
  singlePhotoPrices: [7],
  singleVideoPrices: [],
  currency: 'SGD',
  minimumTransferRequestAmount: 25,
  transferFee: 2,
  minimumPercentShare: 0,
  maximumPercentShare: 70,
  supportedBanks: supportedBankMalayList,
  payoutEnabled: false,
  payoutProvider: 'manual',
  logo_menu: iconRunX,
  lineProtographer: '',
  email: 'photo@brunei.run',
  enableVideoSales: false
}, {
  platform: 'mas',
  webBaseUrl: 'https://photo.mas.run',
  domains: ['admin.photo.mas.run', 'staging.admin.photo.mas.run', 'pro.photo.mas.run', 'staging.pro.photo.mas.run', 'admin.photo.mas.local'],
  logo: iconRunX,
  title: 'Photo Platform MAS.RUN',
  singlePhotoPrices: [11, 15, 16, 17, 18, 19, 20, 25, 30],
  singleVideoPrices: [],
  currency: 'MYR',
  minimumTransferRequestAmount: 100,
  transferFee: 2,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  supportedBanks: supportedBankMalayList,
  payoutEnabled: true,
  payoutProvider: 'manual',
  logo_menu: iconRunX,
  lineProtographer: '',
  email: 'photo@brumasnei.run',
  enableVideoSales: false
}, {
  platform: 'runx',
  webBaseUrl: 'https://photo.runx.run',
  domains: ['admin.photo.runx.run', 'admin.photo.runx-staging.run', 'admin.demo.photo.runx.run', 'admin.photo.runx.local'],
  logo: iconRunX,
  title: 'Photo RunX Admin',
  logo_menu: logoRunX,
  singlePhotoPrices: [3, 4, 5],
  singleVideoPrices: [],
  currency: 'USD',
  minimumTransferRequestAmount: 20,
  transferFee: 1,
  minimumPercentShare: 0,
  maximumPercentShare: 75,
  supportedBanks,
  payoutEnabled: true,
  payoutProvider: 'manual',
  lineProtographer: '',
  email: 'photo@thai.run',
  enableVideoSales: false
}]

export default platforms.find(p => p.domains.some(domain => window.location.origin.includes(domain))) ?? platforms[0]
