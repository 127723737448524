import React, { useState } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import _omitBy from 'lodash/omitBy'
import { Form, Row, Col, Spin, Modal, Button, List, Table } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { inject } from 'mobx-react'
import { compose } from 'recompose'
import moment from 'moment-timezone'
import eventQuery from '~/graphql/queries/event.gql'
import config from '~/config'
import calenderIcon from '~/assets/icons/calendar.svg'
import cardIcon from '~/assets/icons/card.svg'
import locationIcon from '~/assets/icons/location.svg'
import imgIcon from '~/assets/icons/img.svg'
import imgSearchIcon from '~/assets/icons/imageSearch.svg'
import runningIcon from '~/assets/icons/running.svg'
import walletIcon from '~/assets/icons/wallet.svg'
import invoiceIcon from '~/assets/icons/invoice.svg'
import imageIcon from '~/assets/icons/image_1.svg'
import UserPermission from './UserPermission'

// const labelSpan = { xs: { span: 24 }, sm: { span:3, offset: 1} }
// const subLabelSpan = { xs: { span: 24 }, sm: { span: 5, offset: 9 } }
// // const wrapperSpan = { xs: { span: 6, offset: 2 }, sm: { span: 4, offset: 2 } }
// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { offset: 1, span: 4 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 6 },
//   },
// }
const layoutStyle = {
  justifyContent: 'center',
  background: "white",
  border: "1px solid #B7B7B7",
  borderRadius: "25px",
  padding: "25px",
}

// const formItemLongLayout = {
//   labelCol: {
//     xs: { span: 12 },
//     sm: { offset: 1, span: 4 },
//   },
//   wrapperCol: {
//     xs: { span:12 },
//     sm: { span: 12},
//   },
// }

// const tailFormItemLayout = {
//   wrapperCol: {
//     xs: {
//       span: 24,
//       offset: 0,
//     },
//     sm: {
//       span: 8,
//       offset: 10,
//     },
//   },
// }

const EventView = ({ match, user, router }) => {

  const intl = useIntl()
  const { location } = router
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [packageOpen, setPackageOpen] = useState(false);
  const [photographerOpen, setPhotographerOpen] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const isModalClose = () => {
  //   setIsModalOpen(false);
  // };

  const showPackageModal = () => {
    setPackageOpen(true);
  };

  const packageModalClose = () => {
    setPackageOpen(false);
  };

  const showPhotographerModal = () => {
    setPhotographerOpen(true);
  };

  const photographerModalClose = () => {
    setPhotographerOpen(false);
  };

  const { eventId } = match.params
  const { error, loading, data } = useQuery(eventQuery, {
    variables: { _id: eventId },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error...'
  }
  const { event } = data ?? {}
  const uploads = event?.uploads
  const photgrapherCount = event?.uploads?.map((upload) => {return upload?.photographer?.displayName}).length
  const photoPackages = event?.photoPackages ?? []
  const photoPackagesGroup = {}
  const photoUnlimitedPackages = []
  photoPackages.forEach((p) =>
    p.hasUnlimitedItems
      ? photoUnlimitedPackages.push(p)
      : photoPackagesGroup[p.minItemCount]
        ? photoPackagesGroup[p.minItemCount].push(p)
        : (photoPackagesGroup[p.minItemCount] = [p])
  )
  Object.keys(photoPackagesGroup).map((key) => photoPackagesGroup[key].sort((a, b) => a.name.localeCompare(b.name)))
  photoUnlimitedPackages.sort((a, b) => a.name.localeCompare(b.name))

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy({
      ...location.query,
      p: pagination.current && `${pagination.current}`,
      sort
    }, _isEmpty)
  }

  // const photoDownloadMode = {
  //   'free': <FormattedMessage id='app.free' defaultMessage='Free' />,
  //   'priced': <FormattedMessage id='app.priced' defaultMessage='Priced' />
  // }
  // const creditTargets = {
  //   'event': <FormattedMessage id={`app.${creditTargets}`} defaultMessage='Event' />,
  //   'photographer': <FormattedMessage id={`app.${creditTargets}`} defaultMessage='Photographer' />
  // }

  return (
    <UserPermission grantedTo={['superadmin', 'admin']}>
      <Form className='nonPrintDiv' style={{ margin: '0 4em', padding: '2em 0 4em 0' }}>
        <div style={{backgroundColor:'rgba(40, 198, 26, 0.10)', padding: 7, borderRadius: 8}}><div style={{fontSize: 20}}>{config.webBaseUrl}/{event.slug}</div></div>
        <Row justify='space-between'>
          <Col><div style={{fontSize: 50}}>{event.title}</div></Col>
          <Col><Link to={`/events/${event._id}/edit`}><div style={{fontSize: 50}}><Button size='large' style={{ backgroundColor:'#BD188B', color: '#fff'}}><FormattedMessage id='app.edit'/></Button></div></Link></Col>
        </Row>
        <div style={{
          textAlign: 'center',
          backgroundColor: 'rgba(0, 168, 222, 0.10)',
          width: '15%',
          color: '#00A8DE',
          fontSize: 16,
          borderRadius: "15px",
          padding: 10,
          margin: "10px 0px"}}><FormattedMessage id={`app.${event?.photoDownloadMode}`} /></div>
        <Row gutter={[16,16]}>
          <Col flex="12">
            <div>
              <div style={layoutStyle}>
                <Row gutter={[8,8]}>
                  <Col xs={24} sm={12}>
                    <div style={{display: 'flex'}}><img src={calenderIcon} alt='calenderIcon'/><div style={{marginLeft: 5, fontSize: 16}}><FormattedMessage id='app.eventDate' defaultMessage='event Date' /> {moment(event?.startDate).format('LL')}</div></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{display: 'flex'}}><img src={cardIcon} alt='cardIcon'/><div style={{marginLeft: 5, display: 'flex', fontSize: 16}}><FormattedMessage id={`app.${event?.creditTargets}`} defaultMessage='credit Targets' /></div></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{display: 'flex'}}><img src={locationIcon} alt='locationIcon'/><div style={{marginLeft: 5, fontSize: 16}}>{event.province ? <>{event.province}<FormattedMessage id='app.province.note' defaultMessage='province' /></> : '-'}</div></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{display: 'flex'}}><img src={imgIcon} alt='imgIcon'/><div style={{marginLeft: 5, display: 'flex', fontSize: 16}}>{event?.timestamp && intl.formatMessage({ id:'app.timestamp'})} {event?.photoTagFaces && intl.formatMessage({id:'app.faceSearch'})} {event?.photoTagText && intl.formatMessage({id:'app.bibSearch'})}</div></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{display: 'flex'}}><img src={imgSearchIcon} alt='imgSearchIcon'/><div style={{marginLeft: 5, fontSize: 16}}>{moment(event?.searchableAt).format('LL, h:mm:ss a')}</div></div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={{display: 'flex'}}><img src={runningIcon} alt='runningIcon'/><div style={{marginLeft: 5, display: 'flex', fontSize: 16}}>{event?.participantCount ? event?.participantCount : '-'}</div></div>
                  </Col>
                </Row>
              </div>
              <br/>
              <div style={layoutStyle}>
                {(event.singlePhotoPrice || event.singleVideoPrice) && <div style={{backgroundColor:'rgba(0, 168, 222, 0.10)', padding: 7, borderRadius: 8, margin: '15px 0px'}}><div>{event.singlePhotoPrice && (<><FormattedMessage id='app.singlePhotoPrice' defaultMessage='Single Photo Price' /> {event.singlePhotoPrice} <FormattedMessage id='app.baht' defaultMessage='baht' /></>)}{event.singleVideoPrice && <><FormattedMessage id='app.singleVideoPrice' defaultMessage='Single Video Price' /> {event.singleVideoPrice} <FormattedMessage id='app.baht' defaultMessage='baht' /></>}</div></div>}
                <div style={{display: 'flex'}}><img src={imgIcon} alt='imgIcon'/><div style={{marginLeft: 5, display: 'flex', fontSize: 16}}><FormattedMessage id='app.packageCount' defaultMessage='package Count' />&nbsp;:&nbsp;<div onClick={showPackageModal} style={{color: '#00A8DE'}}>{event.photoPackages.length}</div></div></div>
              </div>
              <br/>
              <div style={layoutStyle}>
                <Row gutter={[8,8]}>
                  <Col xs={24} sm={12}><div style={{fontSize: 16}}><FormattedMessage id='app.covers'/></div></Col>
                  <Col xs={24} sm={12}><div style={{fontSize: 16}}><FormattedMessage id='app.eventFrame'/></div></Col>
                </Row>
                <Row gutter={[8,8]}>
                  {event?.coverUrl && <Col xs={24} sm={6}><div style={{fontSize: 16}}><FormattedMessage id='app.coverImage'/><img width='100%' src={event?.coverUrl} alt='coverUrl'/></div></Col>}
                  {event?.videoCoverUrl && <Col xs={24} sm={6}><div style={{fontSize: 16}}><FormattedMessage id='app.coverVideo'/><img width='100%' src={event?.videoCoverUrl} alt='videoCoverUrl'/></div></Col>}
                  {event?.photoFrame?.portraitPreviewUrl && <Col xs={24} sm={6}><div style={{fontSize: 16}}><FormattedMessage id='app.vertical'/><img width='100%' src={event?.photoFrame?.portraitPreviewUrl} alt='coverUrl'/></div></Col>}
                  {event?.photoFrame?.landscapePreviewUrl && <Col xs={24} sm={6}><div style={{fontSize: 16}}><FormattedMessage id='app.horizontal'/><img width='100%' src={event?.photoFrame?.landscapePreviewUrl} alt='videoCoverUrl'/></div></Col>}
                </Row>
              </div>
              <br/>
              <List
                style={{borderRadius: 20, fontSize: 16}}
                  header={<div><FormattedMessage id='app.photographerList'/> ({photgrapherCount})</div>}
                  footer={<div style={{textAlign: 'end', color: '#00A8DE'}} onClick={showPhotographerModal}><FormattedMessage id='app.all'/></div>}
                  bordered
                  dataSource={event?.uploads?.map((upload) => {return <p><UserOutlined /> {upload?.photographer?.displayName}</p>})}
                  renderItem={(item) => (
                    <List.Item>
                      {item}
                    </List.Item>
                  )}
                />
            </div>
          </Col>
          <Col flex="auto">
            <div style={{ justifyContent: 'center',
              background: "rgba(85, 85, 85, 0.10)",
              // border: "1px solid LightGrey",
              borderRadius: "25px",
              padding: "30px"}}>
                <div style={layoutStyle}>
                  <div style={{ justifyContent: 'center',
                    background: "rgba(189, 24, 139, 0.10)",
                    fontSize: 18,
                    border: "1px solid #BD188B'",
                    borderRadius: "15px",
                    padding: "15px 10px"}}>
                    <FormattedMessage id='app.partnerShare'/>
                    </div>
                    <Row>
                      <Col span={8}><div style={{padding: "15px"}}><UserOutlined /> <FormattedMessage id='app.organizer' defaultMessage='Organizer' /></div></Col>
                      <Col span={8}><div style={{padding: "15px", fontSize: 22}}>{`${parseFloat(event.organizerShare).toFixed(2)}\t${event.organizerShareUnit === 'percentage' ? '%' : 'บาท'}`}</div></Col>
                      <Col span={8}><div style={{padding: "15px", color:'#28C61A', fontSize: 22}}>฿<FormattedNumber value={event.organizerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></div></Col>
                    </Row>
                    <Row>
                      <Col span={8}><div style={{padding: "15px"}}><UserOutlined /> <FormattedMessage id='app.broker' defaultMessage='Broker' /></div></Col>
                      <Col span={8}><div style={{padding: "15px", fontSize: 22}}>{`${parseFloat(event.brokerShare).toFixed(2)}\t${event.brokerShareUnit === 'percentage' ? '%' : 'บาท'}`}</div></Col>
                      <Col span={8}><div style={{padding: "15px", color:'#28C61A', fontSize: 22}}>฿<FormattedNumber value={event.brokerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></div></Col>
                    </Row><Row>
                      <Col span={8}><div style={{padding: "15px"}}><UserOutlined /> <FormattedMessage id='app.salesperson' defaultMessage='Sales Thai.run' /></div></Col>
                      <Col span={8}><div style={{padding: "15px",fontSize: 22}}>{`${parseFloat(event.salespersonShare).toFixed(2)}\t${event.salespersonShareUnit === 'percentage' ? '%' : 'บาท'}`}</div></Col>
                      <Col span={8}><div style={{padding: "15px", color:'#28C61A', fontSize: 22}}>฿<FormattedNumber value={event.salespersonShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></div></Col>
                    </Row>
                    <Row>
                      <Col span={8}><div style={{padding: "15px"}}><UserOutlined /> <FormattedMessage id='app.photographer' defaultMessage='Photographer' /></div></Col>
                      <Col span={8}><div style={{padding: "15px", fontSize: 22}}>{`${parseFloat(event.photographerShare).toFixed(2)}\t${event.photographerShareUnit === 'percentage' ? '%' : 'บาท'}`}</div></Col>
                      <Col span={8}><div style={{padding: "15px", color:'#28C61A', fontSize: 22}}>฿<FormattedNumber value={event.photographerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} /></div></Col>
                    </Row>
                </div>
                <br/>
                <div style={layoutStyle}>
                  <Row justify='space-between'>
                    <Col><div style={{fontSize: 18}}><FormattedMessage id="app.totalSold" defaultMessage="Total Sold"/></div></Col>
                    <Col><img src={walletIcon} alt='walletIcon'/></Col>
                  </Row>
                  <div style={{fontSize: 24}}>{event.totalShareAmount}</div>
                </div>
                <br/>
                <div style={layoutStyle}>
                  <Row justify='space-between'>
                    <Col><div style={{fontSize: 18}}><FormattedMessage id="app.photoSold" defaultMessage="app.photoSold"/></div></Col>
                    <Col><img src={imageIcon} alt='imageIcon'/></Col>
                  </Row>
                  <div style={{fontSize: 24}}>{intl.formatNumber(event.totalShareAmountInSharePeriod)}</div>
                </div>
                <br/> 
                <div style={layoutStyle}>
                  <Row justify='space-between'>
                    <Col><div style={{fontSize: 18}}><FormattedMessage id="app.orders" defaultMessage="app.orders"/></div></Col>
                    <Col><img src={invoiceIcon} alt='invoiceIcon'/></Col>
                  </Row>
                  {event.photoSales.length > 0 &&
                    event.photoSales
                    .slice()
                    .sort((a, b) => a.photoPackage?.name.localeCompare(b.photoPackage?.name))
                    .map((photoSale) => {
                      return (
                        <Row>
                          <Col span={8}><div style={{padding: "15px"}}>{photoSale.photoPackage?.name ?? 'No package used'}</div></Col>
                          <Col span={8}><div style={{padding: "15px", fontSize: 22}}>฿<FormattedNumber value={photoSale.amount} minimumFractionDigits={2} maximumFractionDigits={2} /></div></Col>
                          <Col span={8}><div style={{padding: "15px", color:'#28C61A', fontSize: 22}}>{photoSale.orderCount}</div></Col>
                        </Row>
                      )
                  })}
                  <Row justify='space-between'>
                    <Col><div style={{fontSize: 16}}><FormattedMessage id="app.orderAmount" defaultMessage="app.orderAmount"/></div></Col>
                    <Col><div style={{fontSize: 22, color:'#28C61A',}}><FormattedNumber value={event?.photoSales?.reduce((a, b) => a + b.orderCount, 0)} /></div></Col>
                  </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Modal title={<FormattedMessage id='app.packages'/>} style={{ fontFamily: "Kanit"}} 
          width={500}
          open={packageOpen}
          onCancel={packageModalClose}  
          footer={[
            <Button style={{width: "100%", backgroundColor: '#BD188B', color: '#fff'}} key='back' onClick={packageModalClose}>
              <FormattedMessage id='app.action.close' defaultMessage='Close' />
            </Button>
          ]}>
          <div style={{background: 'rgba(0, 168, 222, 0.10)', padding: '0px 20px', display: 'flex', fontSize: 18, borderRadius: 8}}><FormattedMessage id='app.packageCount' defaultMessage='package Count' />&nbsp;:&nbsp;<div>{event.photoPackages.length}</div></div>
            {Object.keys(photoPackagesGroup).length ? (
              <div style={{padding: '10px'}}>
                {Object.keys(photoPackagesGroup).map((key, i) => (<>
                  <Row key={key}>
                    <div style={{padding: "10px", fontSize: 16}}>
                    <FormattedMessage id='app.photosInPackage' defaultMessage='Photos in Package' /> : <FormattedMessage id='app.numberOfPhotos' defaultMessage='{photos} photos' values={{ photos: 1 }} />
                    </div>
                  </Row>
                  <Row key={key?._id}>
                    <Col xs={24} sm={24}>
                      <div style={{ display: 'flex', width: '100%', padding: '5px 10px', color:'#777777'}}><div value={key?._id} style={{ ...(i === 0 )}}>
                        {key?.name} ({key?.packageType} for {key?.photoSize} picture{' '}
                        <FormattedNumber value={key.amount} style='currency' currency={config.currency} currencySign='standard' /> , minimum{' '} {/* eslint-disable-line react/style-prop-object */}
                        {key?.minItemCount} items)
                      </div></div>
                    </Col> 
                  </Row>
                </>
                ))}
              </div>
            ) : (
              <div style={{padding: '10px 20px', fontSize: 16}}><FormattedMessage id='app.noPackage' defaultMessage='No Package' /></div>
            )}
        </Modal>
        <Modal title={<FormattedMessage id='app.packages'/>}  style={{ fontFamily: "Kanit"}} width={500} 
          open={photographerOpen} 
          footer={[
            <Button style={{width: "100%", backgroundColor: '#BD188B', color: '#fff'}} key='back' onClick={photographerModalClose}>
              <FormattedMessage id='app.action.close' defaultMessage='Close' />
            </Button>
          ]}
          onCancel={photographerModalClose}>
          <Table
          rowKey="_id"
          dataSource={uploads ?? []}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: true }}
          >
            <Table.Column
              title={<FormattedMessage id="app.photographerList" defaultMessage="Photographer List" />}
              key='displayName'
              dataIndex={['photographer']}
              render={(_id, {photographer}) => photographer?.displayName} 
            />
            <Table.Column
              title={<FormattedMessage id="app.photoUplaodCount" defaultMessage="Photo Uplaod Count" />}
              key='photoCount'
              dataIndex={['photoCount']}
              render={(_id,{photoCount}) => <>{photoCount}</>} 
            />
            <Table.Column
              title={<FormattedMessage id="app.photographerIncome" defaultMessage="photographer Income" />}
              key='photographerShareAmount'
              dataIndex={['photographerShareAmount']}
              render={(_id,{event}) => <FormattedNumber value={event.photographerShareAmount} minimumFractionDigits={2} maximumFractionDigits={2} />} 
            />
          </Table>
        </Modal>
      </Form>
    </UserPermission>
  )
}

export default compose(inject('user'), inject('router'))(EventView)
