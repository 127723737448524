import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Table, Button, Form, Input, Radio, Tag, Tooltip, Popover, DatePicker, message, Space } from 'antd'
import { CloseCircleOutlined, EditOutlined, HourglassOutlined, QuestionCircleOutlined, SearchOutlined, TeamOutlined, UploadOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import { FormattedMessage, useIntl } from 'react-intl'
// import _orderBy from 'lodash/orderBy'

import eventItemsQuery from '~/graphql/queries/eventItems.gql'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import MaintenanceMessage from '~/components/MaintenanceMessage'
import EventDeleteButton from './EventDeleteButton'
import EventApproveButton from './EventApproveButton'
import { compose } from 'recompose'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import Partnerlogo from '../assets/images/partner.png'
import config from "../config";

const [
  SYMBOL_APPROVED,
  SYMBOL_REJECTED,
  SYMBOL_PENDING
] = ['✔', '✘', '⌛']

const reviewMap = {
  [SYMBOL_APPROVED]: true,
  [SYMBOL_REJECTED]: false,
  [SYMBOL_PENDING]: null
}

const Events = compose(
  inject('user'),
  inject('router')
)(({ user, router }) => {
  const [form] = Form.useForm()
  const { setFieldsValue } = form
  const { location } = router
  // const query = location.query
  const { p: page, s: titleSearch, f: eventFilter, from: _from, to: _to, r: reviewStatus, sort } = location.query

  const from = _from && moment(_from)
  const to = _to && moment(_to)

  const intl = useIntl()
  const approved = reviewMap[reviewStatus]

  const { error, loading, data } = useQuery(eventItemsQuery, {
    variables: {
      titleSearch,
      OR: eventFilter === 'created'
        ? { createdByUserId : user._id}
        // : eventFilter === 'all' && { createdByUserId: !user._id }
        //   ? { approved: true }
          : null,
        // ? [{ registrationMethod: 'auto' }, { registrationMethod: 'manual' }]
        // : eventFilter === 'free'
        //   ? [{ registrationMethod: 'free' }, { registrationMethod: null }]
        //   : null,
      myEvent: eventFilter === 'myEvent',
      ...(from && to && {
        startDateRange: {
          gte: from?.toISOString(),
          lte: to?.toISOString()
        }
      }),
      ...(['broker', 'salesperson'].includes(user.role) && { createdByUserId: user._id }),
      // ...({ approved: reviewStatus && approved }),
      approved,
      sort,
      page: page && parseInt(page, 10)
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    setFieldsValue({ titleSearch, eventFilter: eventFilter ? eventFilter : 'all' })
  }, [titleSearch, eventFilter, setFieldsValue])

  if (error) {
    return message.error(error)
  }


  const handleSearch = (titleSearch) => {
    // const { titleSearch } = values
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: titleSearch,
    }
  }

  const handleFilterChange = (e) => {
    const eventFilter = e.target.value
    const { p, ...query } = location.query
    location.query = _omitBy({
      ...query,
      f: eventFilter,
    }, _isEmpty)
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy({
      ...location.query,
      p: pagination.current && `${pagination.current}`,
      sort
    }, _isEmpty)
  }
  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }
  const setStartDateRange = (from, to) => {
    const { p, from: _from, to: _to, ...query } = location.query
    location.query = {
      ...query,
      ...(from && { from }),
      ...(to && { to }),
    }
  }
  const setReview = (symbol) => {
    const { p, r, ...query } = location.query
    location.query = {
      ...query,
      ...(symbol && { r: symbol }),
    }
  }
  const events= data?.eventItems?.items

  const {
    currentPage: current,
    perPage: pageSize,
    itemCount: total
  } = data?.eventItems?.pageInfo ?? {}

  // const registrationMethodContent = {
  //   'free': <Popover content={<>Queue = {intl.formatMessage({ id: 'app.queue', defaultMessage: 'Queue' })}</>} placement="right"><Tag color="red" icon={<CameraOutlined />}>Queue</Tag></Popover>,
  //   'auto': <Popover content={<>Lotto = {intl.formatMessage({ id: 'app.lotto', defaultMessage: 'Lotto' })}</>} placement="right"><Tag color="red" icon={<CameraOutlined />}>Lotto</Tag></Popover>,
  //   'manual': <Popover content={<>Custom = {intl.formatMessage({ id: 'app.custom', defaultMessage: 'Custom' })}</>} placement="right"><Tag color="red" icon={<CameraOutlined />}>Custom</Tag></Popover>
  // };
  const registrationMethodContent = {
    'free': <>Queue</>,
    'auto': <>Lotto</>,
    'manual': <>Custom</>
  };

  return (
    <MaintenanceMessage>
      <div style={{marginTop: 15, marginLeft: 15, fontSize: 20}}><FormattedMessage id='app.event'/></div>
      <div className="filterTools" style={{ padding: '6px' }}>
        {/* <Form form={form} onFinish={handleSearch}> */}
          <div style={{display: 'flex'}}>
            <Input.Group compact>
              {/* <Form.Item name={['titleSearch']} initialValue={titleSearch}> */}
                  <Input.Search
                    // enterButton
                    prefix={<SearchOutlined />}
                    placeholder={intl.formatMessage({ id: 'app.searchEvent', defaultMessage: 'Search Event' })}
                    defaultValue={titleSearch}
                    allowClear
                    onSearch={handleSearch}
                    style={{ margin: '0px 10px 10px 10px', width: 250, borderRadius: '8px'}}
                  
                  />
              {/* </Form.Item> */}
              {/* <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}></Button>
              </Form.Item> */}
            </Input.Group>
            {(config.platform !== 'thai') ? ['superadmin', 'admin', 'operation_admin'].includes(user.role) && <div style={{display: 'flex', justifyContent: 'end'}}>
            <Link to='/events/create'><Button style={{ backgroundColor: '#BD188B', color: 'white'}}><FormattedMessage id="app.create"/></Button></Link>
            </div>
            : ['superadmin', 'admin', 'operation_admin', 'photographer'].includes(user.role) && <div style={{display: 'flex', justifyContent: 'end'}}>
            <Link to='/events/create'><Button style={{ backgroundColor: '#BD188B', color: 'white'}}><FormattedMessage id="app.create"/></Button></Link>
            </div>}
          </div>
        {/* </Form> */}
      </div>
      {/* <Segmented size="large" onChange={handleFilterChange} options={[
        <FormattedMessage id="app.allEventsFilter" defaultMessage="All" />,
        { value: 'all'},
        <FormattedMessage id="app.eventCreated" defaultMessage="Event Created" />,
        { value: 'created'},
        'Yearly',
      ]} /> */}
      <Radio.Group onChange={handleFilterChange} size="large" style={{borderRadius: 8, margin: "0px 15px" }}>
        <Radio.Button value="all" style={{borderRadius: 8}}><FormattedMessage id="app.allEventsFilter" defaultMessage="All" /></Radio.Button>
        {!['broker', 'salesperson'].includes(user.role) && <Radio.Button size='' value="created" style={{borderRadius: 8}}><FormattedMessage id="app.eventCreated" defaultMessage="Event Created" /></Radio.Button>}
        {/* {!['broker', 'salesperson'].includes(user.role) && <Radio.Button value="free"><FormattedMessage id="app.freeEventsFilter" defaultMessage="Free" /></Radio.Button>} */}
        {!['broker', 'salesperson'].includes(user.role) && <Radio.Button value="myEvent" style={{borderRadius: 8}}><FormattedMessage id="app.myEvent" defaultMessage="My Event" /></Radio.Button>}
      </Radio.Group>
      <div style={{
            justifyContent: 'center',
            background: "white",
            border: "1px solid LightGrey",
            borderRadius: "25px",
            padding: "15px",
            margin: "0px 15px"
          }}>
      <Table
        rowKey="_id"
        dataSource={events}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: true }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          current,
          pageSize,
          total,
        }}
      >
        <Table.Column
          title={<FormattedMessage id="app.eventTitle" defaultMessage="Event Title" />}
          key='title'
          dataIndex={['title']}
          render={( title, { _id, officialPartner }) => {
            const $content = (
              <div style={{ alignItems: 'center', display: 'grid', gap: '10px', gridTemplateColumns: 'auto 1fr' }}>
                {['superadmin', 'admin'].includes(user.role) ? (
                  <Link to={`/events/${_id}/view`}>{title}</Link>
                ) : (
                  <div>{title}</div>
                )}
                {officialPartner && (
                  <Popover content={<>Partner</>}>
                    <img alt='partner'height={25} src={Partnerlogo} width={25} />
                  </Popover>
                )}
                {/* <>{registrationMethodContent[registrationMethod]}{photoDownloadMode === 'free' && <Tag color="red"><FormattedMessage id='app.photoDownloadMode.free' defaultMessage='Free'/></Tag>}</>              */}
              </div>
            )
            if (['superadmin', 'admin', 'operation_admin'].includes(user.role)) {
              return <Popover content={_id}>{$content}</Popover>
            }
            return $content
          }}
        />
        <Table.Column
          title={<FormattedMessage id="app.registrationStartsEndsAt" defaultMessage="Registration Starts - Ends" />}
          key='registrationStartsAt'
          dataIndex={['registrationStartsAt','registrationEndsAt']}
          render={(text, item) => item.registrationStartsAt && item.registrationEndsAt && <>{moment(item.registrationStartsAt).format('Do MMM YYYY')} - { moment(item.registrationEndsAt).format('Do MMM YYYY')}</>}
        />
        {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) &&
          <Table.Column
            title={<FormattedMessage id='app.photographerLimit' defaultMessage='Photographer Limit' />}
            key='photographerNumber'
            dataIndex={['photographerNumber']}
            render={(_id, item) =>
              item.photographerNumber !== null && (
                <p>
                  {item.registrationMethod === 'free'
                    ? `${item?.approvedUploadCount ?? 0}/${item.photographerNumber}`
                    : item.registrationMethod === 'auto'
                    ? `${item?.uploadCount ?? 0}/${item.photographerNumber}`
                    : ``}
                </p>
              )
            }
          />
        }
        
        <Table.Column
          sorter
          filterDropdown={<Space.Compact block>
            <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
            <Button onClick={() => setStartDateRange(null, null)}><FormattedMessage id="app.reset" defaultMessage="Reset" /></Button>
          </Space.Compact>}
          // defaultSortOrder='descend'
          title={<FormattedMessage id="app.eventDate" defaultMessage="Event Date" />}
          key='startDate'
          dataIndex={['startDate']}
          render={(text, item) => moment(item.startDate).format('Do MMM YYYY')}
        />
        <Table.Column
          title={<FormattedMessage id='app.typePhotographer'/>}
          key='registrationEndsAt'
          dataIndex={['_id']}
          render={( _id, { registrationMethod }) =>  <>{registrationMethodContent[registrationMethod]}</>
          // const $content = <>
          //   <>{registrationMethodContent[registrationMethod]}{photoDownloadMode === 'free' && <Tag color="red"><FormattedMessage id='app.photoDownloadMode.free' defaultMessage='Free'/></Tag>}</>             
          // </>
          // if (['superadmin', 'admin', 'operation_admin'].includes(user.role)) {
          //   return <Popover content={_id }>
          //   {$content}
          // </Popover>
          // }
          // return $content
        }
        />
        <Table.Column
          title={<FormattedMessage id="app.uploadPhotographerRequest" defaultMessage="uploadPhotographerRequest" />}
          key='upload'
          dataIndex={['_id']}
          render={(_id, item) => {
            if (user.role === 'customerService') return null
            if (['photographer'].includes(user.role) && moment().isAfter(item.photoFileDeletedAt)) 
            return <Popover content={<FormattedMessage id="app.uploadNotPermitted" defaultMessage="upload Not Permitted" />}><Button icon={<UploadOutlined />} disabled>
            <FormattedMessage id="app.uploadNotPermitted1" defaultMessage="upload Not Permitted" />
          </Button></Popover>
            if (item.allowedPhotographerIds && item.allowedPhotographerIds.length > 0 && !item.allowedPhotographerIds.includes(user._id)) {
              return <Button disabled icon={<WarningOutlined />}>
                <FormattedMessage id="app.uploadRequestRejected" defaultMessage="Upload Request Rejected" />
              </Button>
            }
            return (item.approved
              ? <div><Link to={`/events/${_id}/upload`}>
                {['superadmin', 'admin', 'operation_admin', 'acccounting', 'broker'].includes(user.role) || (['photographer'].includes(user.role) 
                  // admin actions
                  && item.createdByUserId === user._id) ? <Button icon={<TeamOutlined />}>
                    Manage Photographers
                  </Button> 
                  // non admin actions
                  : (['free', 'auto', 'manual'].includes(item.registrationMethod)
                    // if need upload approval
                    ? (item.userUpload
                      ? <React.Fragment>
                        {item.userUpload.approved === true &&
                          <Button icon={<UploadOutlined />} style={{backgroundColor: '#28ACDA', color: 'white'}}>
                            <FormattedMessage id="app.photosUpload" defaultMessage="Photo Upload" />
                          </Button>
                        }
                        {item.userUpload.approved === false &&
                          <Button icon={<WarningOutlined />} style={{backgroundColor:'#FFEAEA', color:'red'}}>
                            <FormattedMessage id="app.uploadRequestRejected" defaultMessage="Upload Request Rejected" />
                          </Button>
                        }
                        {item.userUpload.approved === null &&
                          <Tooltip
                            style={{ whiteSpace: 'pre-line' }}
                            // open={true}
                            title="รออนุมัติสิทธิ์ในการเข้าถ่ายภาพ และ/หรืออัปโหลดภาพเพื่อจำหน่าย หากท่านได้รับสิทธิ์ สถานะจะเปลี่ยนเป็น “อัปโหลด”"
                          >
                            <Button icon={<HourglassOutlined />} style={{backgroundColor:'#FFF7E8', color:'#F3A100'}}>
                              <FormattedMessage id="app.uploadRequestPending" defaultMessage="Upload Request Pending Approval" />
                            </Button>
                          </Tooltip>
                        }
                      </React.Fragment>
                      : (item.registrationMethod === 'free' && item.photographerNumber !== null
                      ? ((item.photographerNumber <= item.approvedUploadCount) ? <Button disabled={moment(item.registrationEndsAt) < moment() || moment(item.registrationStartsAt) > moment() || ['manual'].includes(item.registrationMethod)|| (item.photographerNumber <= item.approvedUploadCount)}>
                        Full
                      </Button>
                      : <Button icon={<EditOutlined />} disabled={moment(item.registrationEndsAt) < moment() || moment(item.registrationStartsAt) > moment() || ['manual'].includes(item.registrationMethod)}>
                      <FormattedMessage id="app.uploadRequest" defaultMessage="Register for upload request" />
                      </Button>)
                      : <Button icon={<EditOutlined />} disabled={moment(item.registrationEndsAt) < moment() || moment(item.registrationStartsAt) > moment() || ['manual'].includes(item.registrationMethod)}>
                          <FormattedMessage id="app.uploadRequest" defaultMessage="Register for upload request" />
                        </Button>)) 
                    // if doesn't need upload approval
                    : <Button icon={<UploadOutlined />}>
                        <FormattedMessage id="app.uploadRequest" defaultMessage="uploadRequest" />
                      </Button>
                  )}
              </Link> {(['photographer'].includes(user.role) && item.photographerNumber !== null) && <React.Fragment>
                {item.registrationMethod === 'free' && <>({item.approvedUploadCount}/{item.photographerNumber})</>}
                {item.registrationMethod === 'auto' && <>({item.uploadCount}/{item.photographerNumber})</>}
                <Tooltip title="จำนวนช่างภาพที่สมัคร/จำนวนช่างภาพที่เปิดรับสมัคร">
                <InfoCircleOutlined />
                </Tooltip> 
                </React.Fragment>
                }
                
              </div>
              : ((item.createdByUserId === user._id && user.role === 'photographer'
                && <Button disabled icon={<TeamOutlined />}>
                Manage Photographers </Button> )
                ? (item.approved === false
                  ? <Popover content={<>{item.approvalMessage} <Button><Link to={`/events/${item._id}/edit`}><FormattedMessage id="app.edit" defaultMessage="Edit" /></Link></Button></>}>
                    <Tag color="red"><CloseCircleOutlined /> <FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Tag> <QuestionCircleOutlined /> <FormattedMessage id="app.viewReason" defaultMessage="View Reason" />
                  </Popover>
                    : <Tag color="orange"><HourglassOutlined /> <FormattedMessage id="app.status.event.pendingReview" defaultMessage="Pending Review" /></Tag>
                )
                : <Button disabled icon={<HourglassOutlined />}>
                  <FormattedMessage id="app.status.event.pending" defaultMessage="Upload" />
                </Button>
              )
            )
          }}
        />
        
        {/* {['admin','operation_admin', 'accounting'].includes(user.role) && <Table.Column
          title={<FormattedMessage id="app.photos" defaultMessage="Photos" />}
          key='photos'
          dataIndex={['_id']}
          render={(_id, {photoCount, photoCountAutoTagged}) =>
            <Link to={`/events/${_id}/photos`} >
              {photoCount === 0
                ? <Button icon={<PictureOutlined />}>
                </Button>
                : Math.ceil(photoCountAutoTagged / photoCount * 100) !== 100
                  ? <Button>
                    {Math.ceil(photoCountAutoTagged / photoCount * 100)}% Tagged
                  </Button>
                  : <Button icon={<PictureOutlined />}>
                  </Button>
              }
            </Link>
          }
        />} */}
        {['admin','operation_admin', 'accounting', 'photographer'].includes(user.role) &&  <Table.Column
          title={<FormattedMessage id='app.photo' />}
          key='photoCount'
          dataIndex={['_id']}
          render={(_id, item) => {return <Link to={`/events/${_id}/photos`} >{item.photosCount !==0 && <div>{item.photosCount} <FormattedMessage id='app.photo' /></div>}</Link>}}
        />}
        {eventFilter === 'created' &&<Table.Column
          title={<FormattedMessage id="app.status" defaultMessage="Status" />}
          key='status'
          dataIndex={['_id']}
          render={(_id, item) => {
            return (
              <div>
                  {(item.createdByUserId === user._id &&
                    <>
                      {item.approved === true &&
                        <Button style={{backgroundColor: '#28ACDA', color: 'white'}}>
                          <FormattedMessage id="app.approved" defaultMessage="Approved" />
                        </Button>
                      }
                      {item.approved === false &&
                      <Popover content={<>{item.approvalMessage} <Link to={`/events/${item._id}/edit`}><Button><FormattedMessage id="app.edit" defaultMessage="Edit" /></Button></Link></>}>
                      <Button type="primary" danger><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Button>
                      </Popover>
                        // <Button style={{backgroundColor:'#FFEAEA', color:'red'}}>
                        //   <FormattedMessage id="app.rejected" defaultMessage="Rejected" />
                        // </Button>
                      }
                      {item.approved === null &&
                        <Button style={{backgroundColor:'rgba(243, 161, 0, 0.10)', color:'#F3A100'}}>
                          <FormattedMessage id="app.pending" defaultMessage="Pending" />
                        </Button>
                      }
                    </>
                    ) 
                  }
              </div>
            )
          }}
        />}

        {['admin','operation_admin', 'accounting'].includes(user.role) &&
          [
            <Table.Column
              title={<FormattedMessage id="app.edit" defaultMessage="Edit" />}
              key='edit'
              dataIndex={['_id']}
              render={(_id) => <Link to={`/events/${_id}/edit`}><Button icon={<EditOutlined />} /></Link>}
            />,
            // <Table.Column
            //   title={<FormattedMessage id="app.remove" defaultMessage="Remove" />}
            //   key='remove'
            //   dataIndex={['_id']}
            //   render={(_id, item) => <EventDeleteButton event={item} />}
            // />,
            <Table.Column
              title={<FormattedMessage id="app.approve" defaultMessage="Approve" />}
              key='approve'
              dataIndex={['_id']}
              filterDropdown={<Radio.Group value={reviewStatus}>
                <Radio.Button value={SYMBOL_PENDING} onClick={() => setReview(SYMBOL_PENDING)}><FormattedMessage id="app.pending" defaultMessage="Pending" /></Radio.Button>
                <Radio.Button value={SYMBOL_APPROVED} onClick={() => setReview(SYMBOL_APPROVED)}><FormattedMessage id="app.approved" defaultMessage="Approved" /></Radio.Button>
                <Radio.Button value={SYMBOL_REJECTED} onClick={() => setReview(SYMBOL_REJECTED)}><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Radio.Button>
                <Radio.Button value={undefined} onClick={() => setReview()}><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
              </Radio.Group>}
              render={(_id, item ) => <EventApproveButton event={item} />}
            />
          ]
        }
        {['superadmin'].includes(user.role) &&
          [
            <Table.Column
              title={<FormattedMessage id="app.edit" defaultMessage="Edit" />}
              key='edit'
              dataIndex={['_id']}
              render={(_id) => <Link to={`/events/${_id}/edit`}><Button icon={<EditOutlined />} /></Link>}
            />,
            <Table.Column
              title={<FormattedMessage id="app.remove" defaultMessage="Remove" />}
              key='remove'
              dataIndex={['_id']}
              render={(_id, item) => <EventDeleteButton event={item} />}
            />,
            <Table.Column
              title={<FormattedMessage id="app.approve" defaultMessage="Approve" />}
              key='approve'
              dataIndex={['_id']}
              filterDropdown={<Radio.Group value={reviewStatus}>
                <Radio.Button value={SYMBOL_PENDING} onClick={() => setReview(SYMBOL_PENDING)}><FormattedMessage id="app.pending" defaultMessage="Pending" /></Radio.Button>
                <Radio.Button value={SYMBOL_APPROVED} onClick={() => setReview(SYMBOL_APPROVED)}><FormattedMessage id="app.approved" defaultMessage="Approved" /></Radio.Button>
                <Radio.Button value={SYMBOL_REJECTED} onClick={() => setReview(SYMBOL_REJECTED)}><FormattedMessage id="app.rejected" defaultMessage="Rejected" /></Radio.Button>
                <Radio.Button value={undefined} onClick={() => setReview()}><FormattedMessage id="app.all" defaultMessage="All" /></Radio.Button>
              </Radio.Group>}
              render={(_id, item) => <EventApproveButton event={item} />}
            />
          ]
        }
      
      </Table>
      </div>
     
    </MaintenanceMessage>
  )
})

export default Events
